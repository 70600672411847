import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import Error from 'components/Error'
import PageHead from 'containers/PageHead'

import { initialHeaderRequest } from 'helpers/nextjsApiCall'
import { pageTitle } from 'helpers/localization'
import { serverSideRedirect } from 'helpers/utilities'

import Login from 'apps/Users/Login'

const LoginPageLoader = (props) => {
  const intl = useIntl()
  const headerData = {
    title: pageTitle(intl, 'login.pageTitle'),
    metaRobots: 'noindex, follow',
  }

  if (props.statusCode) {
    return <Error {...props} />
  }

  return (
    <>
      <PageHead headerData={headerData} />

      <Login {...props} />
    </>
  )
}

LoginPageLoader.getInitialProps = async (props) => {
  const { query, req, res } = props

  const headerResult = await initialHeaderRequest(props)
  const currentUser = headerResult.currentUser

  let redirectTo

  if (query.redirectPath && query.redirectPath.indexOf('http') !== 0) {
    redirectTo = query.redirectPath
  }

  if (currentUser.isSignedIn) {
    if (query.callback_url && query.object_id && query.project_id) {
      const redirectUrl = `/nexus/signed_url?callback_url=${query.callback_url}` +
          `&object_id=${query.object_id}&project_id=${query.project_id}`

      serverSideRedirect(res, redirectUrl)
    }

    if (currentUser.isAdmin) {
      serverSideRedirect(res, redirectTo || '/admin')
    }

    if (currentUser.hasProviders) {
      serverSideRedirect(res, redirectTo || '/provider-dashboard/requests')
    }

    serverSideRedirect(res, redirectTo || '/projects')
  }

  if (req.headers.referer && !redirectTo) {
    const refererUrl = new URL(req.headers.referer)

    if (refererUrl.host === req.headers.host) {
      redirectTo = refererUrl.pathname
    }
  }

  return {
    ...headerResult,
    skipDeferringStyles: true,
    redirectTo,
  }
}

LoginPageLoader.propTypes = {
  statusCode: PropTypes.number,
}

export default LoginPageLoader
